.featured {
    background: #ffedf7;
    position: relative;
    padding-bottom: 100px;
}

.featured-header {
    font-size: 50px;
    margin-bottom: 100px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.featured-products {
    display: flex;
    justify-content: center;
    align-items: center;

}

.webp .featured-banner {
    background-image: url(../featured-banner.webp);
}

.no-webp .featured-banner {
    background-image: url(../featured-banner.png);
}

.featured-banner {
    height: 500px;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    background-image: url(../featured-banner.png);
    position: relative;
    margin-bottom: 100px;
}

.view-more-button {
    border: 1px solid #684154;
    transition: .3s ease;
    text-align: center;
    margin: 50px auto 20px auto;
    width: 180px;
}

.view-more-button a {
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: #684154;
    background-color: transparent;
    font-size: 16px;
    transition: .3s ease;
}

.view-more-button:hover a {
    text-decoration: none;
    background-color: #684154;
    color: #ffedf7;
}

.view-more-button:hover {
    border: 1px solid #ffd4eb;
}

.featured-content .col-md {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 1366px) {
    .featured {
        margin-top: 0;
    }

    .featured-header {
        font-size: 40px;
        text-align: center;
    }

    .featured-banner {
        height: 35vh;
        background-attachment: scroll;
    }

    .featured-banner h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 600px) {
    .featured-content .col-md {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: unset;
    }
}