
.hero-main {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.hero-main img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
}

.hero-main h1 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    z-index: 0;
}

.webp .hero-background {
    background-image: url(../hero-background.webp);
}

.no-webp .hero-background {
    background-image: url(../hero-background.webp);
}

/*noinspection CssUnresolvedCustomProperty,CssOverwrittenProperties*/
.hero-background {
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    background-position: calc(var(--x) / (-350)) calc(var(--y) / (-350));
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
}

/*noinspection CssUnresolvedCustomProperty*/
.hero-logo {
    transform: translateX(calc(var(--x) / (-50))) translateY(calc(var(--y) / (-50)));
    z-index: 3;
}

.down-arrows {
    position: absolute;
    top: 80%;
    left: 50%;
    z-index: 3;
}

.down-arrows span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid #684154;
    border-right: 2px solid #684154;
    transform: rotate(45deg);
    margin: -5px -10px;
    animation: animate 2s infinite;
}

.down-arrows span:nth-child(1) {
    animation-delay: -0.6s;
}

.down-arrows span:nth-child(2) {
    animation-delay: -0.4s
}

.down-arrows span:nth-child(3) {
    animation-delay: -0.2s
}

.hero-logo {
    display: block;
}

.hero-logo-small {
    display: none;
}

@keyframes animate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media only screen and (max-width: 1366px) {
    .hero-logo {
        height: 70% !important;
        top: 10%;
        transform: initial;
    }

    .webp .hero-background {
        background-image: url(../hero-background-tablet.webp);
    }

    .no-webp .hero-background {
        background-image: url(../hero-background-tablet.webp);
    }

    .hero-background {
        background-attachment: scroll;
    }

    .hero-main h1 {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .hero-logo {
        display: none;
    }

    .hero-logo-small {
        display: block;
    }

    .hero-main img {
        object-fit: contain;
    }

    .webp .hero-background {
        background-image: url(../hero-background-mobile.webp);
    }

    .no-webp .hero-background {
        background-image: url(../hero-background-mobile.webp);
    }
}
