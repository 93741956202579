.products-container{
    padding-top: 80px;
}

.products-banner {
    height: 400px;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.products-banner-alt {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.products-header{
    font-size: 50px;
    margin-bottom: 100px;
    text-transform: uppercase;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.products-content{
    padding: 70px 50px;
    background: #ffedf7;
}

.products-container .col-md {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 1920px) {
    .products-banner-alt {
        height: 400px;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }
}

@media only screen and (max-width: 1400px) {
    .products-banner-alt {
        height: 300px;
        background-size: cover;
        background-position-x: 40%;
        background-repeat: no-repeat;
        position: relative;
    }
}

@media only screen and (max-width: 1000px){
    .products-container .col-md {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: unset;
    }
    .products-banner {
        height: 30vw;
        background-size: contain;
        background-position: bottom;
    }
    .products-banner-alt {
        height: 200px;
    }
}

@media only screen and (max-width: 600px) {
    .products-banner-alt {
        height: 100px;
        background-position: top;
    }
}