.privacy-container,
.tos-container,
.refund-container{
    margin-top: 80px;
    padding: 50px 15% 150px 15%;
    background: #ffedf7;
}

.privacy-container h1,
.tos-container h1,
.refund-container h1{
    text-align: center;
    color: black;
    padding: 40px 0;
}

.privacy-container h2,
.tos-container h2,
.refund-container h2{
    margin-top: 50px;
}

ul {
    margin-left: 2em;
}