.team-container{
    margin-top: 80px;
    width: 100%;
    background:  #ffedf7;
    padding-bottom: 150px;
}

.team-container .col-lg{
    padding-left: 50px!important;
    padding-right: 50px!important;

}

.team-header{
    text-align: center;
    padding: 100px 0;
}

.teamcard-container{
    position: relative;
    width: 100%;
    background: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.cover-image img{
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.profile-image{
    margin-top: -75px;
}

.profile-image img{
    z-index: 1;
    width: 150px;
    height: 150px;
    position: relative;
    display: block;
    margin: 0 auto;
    border-radius: 100px;
    border: 8px solid white;
}

.person-content h2, h3, p {
    font-family: sans-serif;
}

.person-content h2{
    font-size: 24px;
    text-align: center;
    margin: 10px 0 5px 0;
}

.person-content h3{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
}

.person-content p{
    text-indent: 40px;
    font-size: 16px;
    padding: 0 40px 5px 40px;
}

.social-icons{
    text-align: center;
    margin-bottom: 50px;
}

.social-icons .fa-link,
.social-icons .fa-facebook-f,
.social-icons .fa-instagram{
    text-decoration: none;
    font-size: 30px;
    color: rgb(255, 180, 192);
    padding: 0 30px;
    margin: 20px 0 0 0;
    transition: 0.3s;
}

.social-icons .fa-link:hover,
.social-icons .fa-facebook-f:hover,
.social-icons .fa-instagram:hover{
    color: #684154;
}

