.image-item-container {
    display: flex;
    flex-direction: column;
}

.image-item-information {
    margin-top: 10px;
    margin-bottom: 10px;
}

.image-item-container svg {
    margin-left: 10px;
}

.image-item-container .fa-crop {
    color: black;
}

.image-item-container .fa-crop:hover {
    color: white;
}

.image-item-container .fa-trash-alt {
    color: red;
}

.image-item-container .fa-trash-alt:hover {
    color: black;
}

.crop-container {
    width: 100%;
    height: 600px;
    margin-bottom: 20px;
    position: relative;
}

.image-preview-container img {
    max-width: 100%;
    max-height: 400px;
}

@media only screen and (max-width: 1000px){
    .crop-container {
        height: 250px;
    }
}

@media only screen and (max-width: 400px){
    .crop-container {
        height: 200px;
    }
}