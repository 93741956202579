.footer{
    background: white;
    position: relative;
}

.footer-socials{
    text-align: center;
    padding-top: 80px;
    padding-bottom: 40px;
}

.footer-socials a{
    text-decoration: none;
}

.fa-facebook-f,
.fa-instagram,
.fa-tiktok,
.fa-twitter,
.fa-youtube{
    color: pink;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 40px;
    transition: 0.3s ease;
}

.fa-facebook-f:hover,
.fa-instagram:hover,
.fa-tiktok:hover,
.fa-twitter:hover,
.fa-youtube:hover{
    color: #684154;
}

.policies{
    display: flex;
    justify-content: center;
}
.policies a{
    text-decoration: none;
    color: black;
    text-align: center;
    font-size: 16px;
    margin: 0 40px;
}

.footer-email{
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer-email a{
    color: black;
    text-decoration: none;
    transition: 0.3s ease;
}

.footer-email:hover a{
    color: pink;
}

.footer-bottom{
    color: black;
    text-align: center;
    font-size: 12px;
    padding-bottom: 80px;
}

.footer-bottom a{
    color: black;
    text-decoration: none;
    transition: 0.3s ease;
}

.footer-bottom a:hover{
    color: pink;
}

@media only screen and (max-width: 850px){
    .policies{
        display: block;
        text-align: center;
    }
    .policies div {
        margin-bottom: 16px;
    }
    .footer-socials {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding-top: 40px;
    }
    .fa-facebook-f,
    .fa-instagram,
    .fa-tiktok,
    .fa-twitter,
    .fa-youtube{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

