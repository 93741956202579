.faq-container{
    margin-top: 80px;
    padding: 50px 15% 150px 15%;
    background: #ffedf7;
}

.faq-container h1{
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 20px;
}

.faq-container h2{
    font-size: 20px;
}

.faq-container ol {
    margin-left: 2em;
}

#title {
    font-size: 50px;
    text-align: center;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 570px){
    .faq-container h1{
        font-size: 24px;
    }

    .faq-container h2{
        font-size: 16px;
    }

    #title {
        font-size: 40px;
    }
}