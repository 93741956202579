* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    overflow-x: hidden;
}

p, h1, h2, h3, h4, a {
    font-family: 'M PLUS Rounded 1c', sans-serif;
}
