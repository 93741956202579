.checkout-container{
    z-index: 11;
    /*padding-top: 80px;*/
    background: #ffedf7;
    position: relative;
    display: flex;
    padding-bottom: 100px;
}

.checkout-content{
    width: 60%;
    padding: 50px 60px;
}

.checkout-head{
    text-align: center;
}

.checkout-head p{
    font-size: 16px;
    color: black;
    margin: 30px 0;
}

.checkout-head img{
    width: 200px;
}

.checkout-options{
    display: flex;
}

.checkout-options a{
    text-decoration: none;
    color: black;
}

.order-summary{
    width: 60%;
    padding: 50px 60px;
    border-right: 1px solid rgb(177, 177, 177);
}

/* Shipping information */

.shipping-information p{
    text-align: center;
}

.shipping-information h1{
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: 50px;
    font-weight: bolder;
    text-align: center;
}

.checkout-options button{
    margin-left: auto; 
    margin-right: 0;
}

.btn-primary{
    background-color: #684154!important;
    border-color: #684154!important;
}

.btn-primary a{
    color: white;
}

.btn-primary:hover {
    background-color: #d674a1!important;
    border-color: #d674a1!important;
}

.btn-primary:active {
    background-color: #180d13 !important;
    border-color: #180d13!important;
}

.paypal{
    border-radius: 10px;
    max-width: 500px;
    transition: .3s ease;
    margin: 0 auto;
}

.paypal a{
    border-radius: 10px;
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: black;
    font-size: 16px;
    background-color: #F7C557;
    transition: .2s ease;
    text-align: center;
}

.or{
    display: flex;
    margin: 50px 0;
}

.or p{
    margin: 0 10px;
}

.or .line{
    width: 100%;
    height: 1px;
    background-color: rgb(73, 73, 73);
    margin-top: 13px;
}


/* Payment Method */


.shipping-summary-container{
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 50px;
}

.shipping-summary{
    display: flex;
}

.shipping-summary:nth-of-type(1),
.shipping-summary:nth-of-type(2){
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.shipping-summary-content{
    display: flex;
}

.shipping-summary-content p:nth-of-type(1){
    width: 100px;
    font-size: 14px;
    color: gray;
    margin: 0;
}

.shipping-summary-content p:nth-of-type(2){
    font-size: 14px;
    color: black;
    margin: 0;
}

.shipping-summary a{
    margin-left: auto;
    margin-right: 0;
    font-size: 12px;
    color: black;
    text-decoration: none;
}

.payment,
.billing{
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 50px;
    overflow: hidden;
}

.credit-head,
.shipping-head{
    border-bottom: 1px solid black;
    padding: 10px 20px 10px 20px;
    background-color: #684154;
}

.paypal-head,
.billing-head{
    border-top: 1px solid black;
    padding: 10px 20px;
    background-color: #684154;
}

.credit-head label,
.shipping-head label,
.paypal-head label,
.billing-head label{
    color: white;
    width: 100%;
}

.credit-forms,
.billing-forms{
    padding: 10px 20px;
    background-color: #ffedf7;

}

.credit-forms .form-group{
    margin: 0!important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.credit-forms.hidden,
.billing-forms.hidden{
    display: none;
}

/* Order Summary */

.discount-code{
    padding: 25px 0 10px 0;
    border-top: 1px solid black;
}

.discount-code button{
    height: calc(1.5em + .75rem + 2px);
}


.subtotal-price-container{
    border-top: 1px solid black;
    padding-top: 25px;
    padding-bottom: 25px;
}

.subtotal-price-container p{
    margin: 0;
}

.subtotal-price{
    display: flex;
}

.subtotal-price p:nth-of-type(2),
.final-price p:nth-of-type(2){
    margin-left: auto;
    margin-right: 0;
}

.final-price{
    display: flex;
    border-top: 1px solid black;
    padding-top: 25px;
}

.final-price p{
    font-size: 20px;
}

.international-shipping {
    padding: 10px 10px 5px 10px;
}

.international-shipping label {
    font-family: sans-serif;
}

.international-shipping input {
    margin-right: 5px;
}

.post-checkout {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    color: #fff;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    top: 45%;
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 900px){
    .checkout-container{
        display: block;
    }
    .checkout-content{
        width: 100%;
        padding: 10px 40px;
    }
    .order-summary{
        width: 100%;
        padding: 80px 60px 50px 60px;
        border-left: none;
        border-bottom: 1px solid black;
    }
}