.item-footer-container{
    position: fixed;
    height: 80px;
    width: 100%;
    bottom: 0;
    background-color: #684154;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-count a{
    text-decoration: none;
    color: white;
    padding-bottom: 5px;
    border-bottom: 2px solid white;
}

.checkout-button{
    border: 1px solid white;
    width: 150px;
    transition: .3s ease;
    text-align: center;
}

.checkout-button a{
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: white;
    background-color: transparent;
    font-size: 16px;
    transition: .2s ease;
}

.total-price p{
    margin: 0 30px;
    color: white;
}