.catalog-card-container{
    position: relative;
    margin-bottom: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.catalog-card-container img{
    object-fit: cover;
    width: 100%;
}

.catalog-card-container:hover img{
    filter: grayscale(100%);
}

.catalog-card-name-container p{
    font-weight: 600;
    color: rgb(185 85 134);
    text-align: center;
    pointer-events: none;
    font-size: 36px;
    z-index: 2;
}

.catalog-card-name-container p::after{
    content: '';
    display: block;
    width: 0;
    height: 4px;
    background: white;
    transition: width .3s;
}

.catalog-card-name-container:hover p::after{
    width: 100%;
    transition: width .3s;
}

@media only screen and (max-width: 400px) {
    .catalog-card-name-container p{
        font-weight: 500;
        font-size: 30px;
    }
}