.catalog-container {
    padding-top: 80px;
}

.catalog-container h1 {
    margin-bottom: 100px;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
}

.catalog-banner {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.catalog-header {
    font-size: 50px;
    margin-bottom: 100px;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.catalog-content {
    padding: 70px 50px;
    background: #ffedf7;
}

.catalog-container .col-md {
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
}

.view-more-button {
    border: 1px solid #684154;
    transition: .3s ease;
    text-align: center;
    margin: 50px auto 20px auto;
    width: 180px;
}

.view-more-button a {
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: #684154;
    background-color: transparent;
    font-size: 16px;
    transition: .3s ease;
}

.view-more-button:hover a {
    text-decoration: none;
    background-color: #684154;
    color: #ffedf7;
}

.view-more-button:hover {
    border: 1px solid #ffd4eb;
}

@media only screen and (max-width: 1000px) {
    .catalog-container .col-md {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: unset;
    }
}

@media only screen and (max-width: 820px) {
    .catalog-banner {
        background-size: cover;
        background-attachment: inherit;
        height: 200px;
        background-position: center;
    }
}

@media only screen and (max-width: 820px) {
    .catalog-banner {
        background-size: cover;
        background-attachment: inherit;
        height: 200px;
        background-position: center;
    }
}

@media only screen and (max-width: 340px) {
    .catalog-banner {
        background-size: contain;
        background-attachment: fixed;
        height: 60px;
        background-position-y: 80px;
    }
}