.checkout-item{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.checkout-item p{
    margin-bottom: 0;
}

.checkout-item img{
    max-height: 100px;
    width: 100px;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.checkout-item-info{
    display: flex;
    width: 100%;
}

.right{
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: center;
    font-weight: bolder;
}

.checkout-item-info-images p {
    display: inline-block;
    font-size: 80%;
}

.checkout-item-info-expanded {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.test button, .checkout-item-info-expanded a {
    font-size: 80%;
    font-family: sans-serif;
    border: 0;
    padding: 0;
    background: none;
    color: #0000FF;
}

.checkout-item-info-expanded a {
    margin-right: 5px;
}

.test button:active {
    color: #EE0000;
}

@media only screen and (max-width: 450px){
    .checkout-item {
        flex-wrap: wrap;
    }
    .checkout-item-info{
        margin-top: 1em;
    }
}