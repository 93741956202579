.product-details-container {
    background: #ffedf7;
    z-index: 11;
    position: relative;
    display: flex;
    padding: 60px 40px 500px 40px;
}

.buy {
    display: grid;
    grid-template-columns: 120px auto;
}

.exit {
    position: fixed;
    top: 20px;
    right: 20px;

}

.exit button {
    text-decoration: none;
    background: black;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 50px;
}

.exit .fa-times {
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
}

.details-left-container {
    width: 100%;
    z-index: 12;
}

.details-left-container .product-view {
    position: fixed;
}

.smooth-link > .active > img {
    border: 3px solid black;
}

.details-left-container img {
    width: 100px;
    margin-bottom: 30px;
}

.details-middle-container .row {
    margin-bottom: 100px;
}

.details-right-container {
    padding: 40px 20px 40px 40px;
    position: relative;
    width: 40%;
    margin: 0 auto;
}


.details-right-container h1 {
    font-size: 40px;
}

.details-right-container h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.details-right-container h3 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.add-cart {
    border: 1px solid #684154;
    width: 100%;
    transition: .3s ease;
    text-align: center;
    margin: 20px auto 0 auto;
}

.add-cart input {
    text-decoration: none;
    display: inline-block;
    padding: 15px;
    width: 100%;
    color: #684154;
    background-color: transparent;
    font-size: 16px;
    transition: .2s ease;
}

.add-cart:hover input {
    background-color: #684154;
    color: #ffedf7;
    transition: .2s ease;
}


.front,
.side,
.back {
    object-fit: cover;
}

.front img,
.side img,
.back img {
    width: 100%;
    max-width: 600px;
}

.upload-button {
    background: linear-gradient(-90deg, #70505f, #41182b);
    padding: 8px 15px;
    color: white;
    font-size: 12px;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-right: 20px;
}

.productCustomizer .input-area {
    display: flex;
}

.productCustomizer input {
    margin-top: 25px;
}

.input-message textarea {
    min-height: 100px;
    max-height: 300px;
    height: 150px;
    resize: vertical;
}

.form-check {
    padding: 10px;
}

.area {
    width: 100%;
    height: 100%;
    border: 4px dashed #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 64px 64px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    filter: alpha(opacity=50);
    -khtml-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
    text-align: center;
    margin-bottom: 25px;
    padding: 25px;
}

.area input {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
}

.area input:focus {
    outline: none;
}

.area .spinner {
    display: none;
    margin-top: 50%;
}

.area:hover,
.area.dragging {
    filter: alpha(opacity=100);
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
}

.area span {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.warning-message {
    margin-top: 10px;
    margin-bottom: 10px;
}

/*noinspection DuplicatedCode*/
@media only screen and (max-width: 750px) {
    .details-middle-container .row {
        margin-bottom: 25px;
    }

    .details-right-container h1 {
        font-size: 28px;
    }

    .details-right-container h2 {
        margin-top: 15px;
        font-size: 20px;
    }

    .product-details-container {
        display: block;
    }

    .details-right-container {
        width: 100%;
        padding-left: 150px;
        padding-top: 0;
    }

    #upload-text {
        margin-top: 10px;
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .product-details-container {
        padding: 60px 40px 200px 40px;
    }

    .details-left-container {
        display: none;
    }

    .details-right-container {
        width: 100%;
        padding-left: 20px;
    }

    .buy {
        display: grid;
        grid-template-columns: 70% auto;
        justify-content: center;
    }

    .exit a {
        width: 40px;
        height: 40px;
    }

    .exit .fa-times {
        font-size: 20px;
    }
}