.product-card-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.product-card{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

}

.product-content{
    position: absolute;
    bottom: -160px;
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    flex-direction: column;
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: bottom 0.5s;

}

.product-card:hover .product-content{
    bottom: 0;
}

h3:hover .product-content{
    bottom: 0;
}

.product-content-box h1,
.product-content-box h2{
    color: black;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
}

.product-content-box h2{
    font-size: 16px;
}

.product-card:hover .product-content .product-content-box h1,
.product-card:hover .product-content .product-content-box h2{
    opacity: 1;
}

.product-card-container h3{
    cursor: default;
    font-size: 28px;
}

.product-card-container h3 span{
    cursor: default;
    font-size: 16px;
}

.product-content .size{
    position: relative;
    display: flex;
    margin-top: 15px;
}

.product-content .size li{
    list-style: none;
    margin: 0 15px;
    transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
}

.product-card:hover .product-content .size li{
    transform: translateY(0px);
    opacity: 1;
}

.product-content .size li a{
    color: black;
    text-decoration: none;
    font-size: 16px;
    transition: 0.3s;
}   

.size li a::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: black;
    transition: width .3s;
    margin-top: 2px;
}

.size li:hover a::after{
    width: 100%;
    transition: width .3s;
}

.product-image{
    object-fit: cover;
}

.product-image img{
    width: 100%;
}

.notsale{
    display: none;
}

.sale{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background: rgb(255, 139, 159);
}

.sale p, .new p{
    margin: 0;
}

.new{
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    background: rgb(0, 202, 255);
}

.line-through{
    text-decoration: line-through;
    color: gray;
}

.sale-price{
    color: red;
}

.hidden{
    display: none;
}

@media only screen and (min-width:768px) and (max-width:950px){
    .product-content .size li a{
        font-size: 12px;
    }
    .product-content .size li{
        margin: 0 10px;
    }
}

