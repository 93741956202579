.cart-container{
    margin-top: 80px;
    background: #ffedf7;
    padding-bottom: 100px;

}

.cart-container h1{
    text-transform: uppercase;
    text-align: center;
    padding: 100px 0 30px 0;
}

.hidden{
    display: none;
}

.cart-empty{
    margin-top: 100px;
    text-align: center;
}

.cart-full{
    margin: 80px auto;
    text-align: center;
    width: 80%;
}

.cart-full table{
    width: 100%;
    border-collapse: collapse;
}

.item-info{
    display: flex;
    flex-wrap: wrap;
    text-align: initial;
}

.item-info p{
    margin: 0;
}

th{
    text-align: left;
    padding: 5px;
    color: black;

    border-bottom: 2px solid black;
    font-weight: normal;
}

td{
    padding: 10px 5px;
    text-align: initial;
}

td input{
    width: 40px;
    height: 30px;
    padding: 5px;
}

td a{
    color: red;
    font-size: 12px;
}

td a:hover{
    color: red;
}

td img{
    max-width: 100%;
    height: 200px;
    margin-right: 10px;
}

td:last-child{
    text-align: right;
}

th:last-child{
    text-align: right;
}

.subtotal{
    width: 100%;
    text-align: right;
    border-top: 2px solid black;
    padding-top: 20px;
}

.subtotal h2{
    font-size: 24px;
}

.subtotal h3{
    font-size: 12px;
}

.cart-checkout{
    border: 1px solid black;
    width: 150px;
    transition: .3s ease;
    text-align: center;
    margin-left: auto; 
    margin-right: 0;
    margin-top: 30px;
}

.cart-checkout a{
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    width: 100%;
    color: black;
    background-color: transparent;
    font-size: 16px;
    transition: .3s ease;
}

.cart-checkout a:hover{
    background-color: white;
}

.continue-shopping{
    margin-top: 30px;
}

.continue-shopping a{
    color: black;
    text-decoration: none;
}

.item-info a {
    padding-right: 5px;
}

@media only screen and (max-width: 600px){
    .cart-container h1{
        font-size: 34px;
    }
    .item-info img {
        max-height: 150px;
    }
}