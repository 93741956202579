.order-complete-container{
    margin-top: 80px;
    min-height: 100vh;
    background: #ffedf7;
    padding: 50px 15% 150px 15%;
}

.order-complete-outer{
    padding: 20px;
    background: white;
    border-radius: 5px;
    border: 1px solid rgb(236, 236, 236);
    width: 80%;
    margin: 0 auto;
}

.order-complete-inner{
    background: #ffedf7;
    border-radius: 5px;
    border: 1px solid  rgb(236, 236, 236);
    padding: 20px;
}

.order-complete-inner h1{
    font-size: 40px;
    margin-bottom: 20px;
}

.order-complete-inner h2{
    font-size: 24px;
    margin-bottom: 20px;
}

.order-information, .order-shipping-information, .order-images {
    margin-top: 3em;
}

.order-images img {
    max-width: 100%;
    max-height: 200px;
    margin: 1em 1em 2em;
}

.order-images h3 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.image-toggle-button {
    background: linear-gradient(-90deg, #70505f, #41182b);
    padding: 8px 15px;
    color: white;
    font-size: 12px;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-right: 20px;
}

.order-shipping-information a {
    font-family: sans-serif;
}

@media only screen and (max-width: 800px){
    .order-complete-container{
        padding: 50px 5% 150px 5%;
    }
    .order-complete-outer{
        width: 90%;
    }
    .order-complete-outer h1, h2 {
        font-family: sans-serif;
    }
    .order-complete-inner h1{
        font-size: 18px;
    }
    .order-complete-inner h2{
        font-size: 16px;
    }
    .order-complete-inner p{
        font-size: 12px;
    }
}