.navbar-container {
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), transparent);
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: 0.3s ease;
}

.navChange {
    background-color: #ffedf7;
    transition: 0.3s ease;
}

.colored {
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), transparent);
    background-color: #ffedf7;
}

.navbar-logo {
    color: white;
    justify-self: start;
    margin-left: 100px;
    cursor: pointer;
}

.navbar-logo img {
    width: 220px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 40px;
    list-style: none;
    text-align: center;
    width: 75vw;
    justify-content: end;
    margin-right: 2rem;
    margin-bottom: 0;
}

.nav-links {
    color: black;
    text-decoration: none;
    padding: 8px 10px 8px 10px;
    font-size: 18px;
    transition: all 0.2s ease;
}

.nav-links:hover {
    text-decoration: none;
    color: black;
    transition: all 0.2s ease;
}

.nav-links-active {
    text-decoration: none;
    color: black;
    border-bottom: 2px solid black;
}

.colored .nav-links-active,
.navChange .nav-links-active {
    color: #191a52 !important;
    border-bottom: none;
}

.colored .nav-links,
.navChange .nav-links {
    color: #ff89ca;
}

.colored .nav-links:hover,
.navChange .nav-links:hover {
    color: #191a52;
}

.fa-bars {
    color: #ff9cd2;
}

.fa-times {
    color: #ff9cd2;
    font-size: 2rem;
}

.menu-icon {
    display: none;
}


@media screen and (max-width: 1366px) {
    .navbar-container {
        position: fixed;
        background: #ffedf7;
    }
}


@media screen and (max-width: 800px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        margin-left: 0;
        justify-content: flex-start;
    }

    .nav-menu.active {
        background: #ffedf7;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10;
    }

    .nav-links {
        text-align: center;
        padding: 24px;
        width: 100%;
        display: table;
        border-radius: 0;
        color: #ff89ca;
    }

    .nav-links:hover {
        color: black;
        border-radius: 0;
    }

    .nav-links-active {
        border-bottom: none;
        color: black !important;
    }

    .navbar-logo {
        position: absolute;
        margin-left: -20px;
        top: 0;
        left: 0;
        transform: translate(25%, 75%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 40%);
        font-size: 30px;
        cursor: pointer;
    }
}